@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

body {
    font-family: 'Noto Sans', sans-serif;
}

h1 {
    text-align: center;
}

footer {
    text-align: center;
    font-style: italic;
}

.password {
    color: white;
    background: black;
}

@media only screen and (min-width: 600px) {
    .jumbotron {
        padding: 1rem 1rem;
    }
}